<template>
    <div>
        <mainheader></mainheader>
        <mainbox></mainbox>
        <section>
            <div class="container">
                <div class="article-container">
                    <div class="article-wrapper">
                        <span class="article-title">{{ translation.translate('article4', 'title') }}</span>
                        <br>
                        <div class="article-content">
                            <img class="article-small-logo" src="../../assets/images/svg/edo2.svg" />
                            <span class="artice-small-text"> {{ translation.translate('article4', 'article') }}</span>

                        </div>
                        <img class="article-main-logo" src="../../assets/images/svg/articles/article4.svg" />
                        <p class="article-text">{{ translation.translate('article4', 'text-content-1') }} </p>
                        <div class="paragraph">
                            <span class="article-text">&nbsp;&nbsp;&nbsp;</span> 
                            <div class="article-text article-text-variants"> 
                                <p class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-1') }}</p> 
                                <p class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-2') }}</p> 
                                <p class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-3') }}</p> 
                                <p class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-4') }}</p> 
                                <p class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-5') }}</p> 
                                <p class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-6') }}</p> 
                            </div>
                        </div>
                        <div class="article-questions-btn-container">
                            <button class="article-questions-btn" @click="redirectToPetition()">{{ translation.translate('article4', 'questions-btn') }} </button>
                        </div>
                        <p class="article-text">{{ translation.translate('article4', 'text-content-2') }} </p>
                        <div class="paragraph">
                            <span class="article-text">1.</span> 
                            <p class="article-text"> <span class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-7') }}</span> {{ translation.translate('article4', 'text-content-3') }} </p>
                        </div>
                        <div class="paragraph">
                            <span class="article-text">2.</span> 
                            <p class="article-text"> <span class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-8') }}</span> {{ translation.translate('article4', 'text-content-4') }} </p>
                        </div>
                        <p class="article-text">{{ translation.translate('article4', 'text-content-5') }} </p>
                        <p class="article-text">
                            {{ translation.translate('article4', 'text-content-6') }}
                            <span class="article-text-bold">{{ translation.translate('article4', 'bold-text-content-9') }}</span>
                            {{ translation.translate('article4', 'text-content-7') }}
                        </p>
                    </div>
                </div>
                <div class="article-footer-container">
                    <div class="article-footer">
                        <p class="article-footer-text">
                            {{ translation.translate('article4', 'footer-text') }}
                            <span class="article-text-bold">{{ translation.translate('article4', 'footer-bold-text') }}</span>
                        </p>

                        <div class="article-questions-btn-container">
                            <button class="article-questions-btn" @click="redirectToPetition()">{{ translation.translate('article4', 'footer-btn') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <mainfooter></mainfooter>
    </div>
</template>


<script>
import translate from '../../../translation/translate'
import mainbox from "./../mainbox.vue";
import mainheader from "./../mainheader.vue";
import mainfooter from "./../mainfooter.vue";

export default {
    name: 'article4',
    data() {
        return {
            translation: translate,

        }
    },
    components: {
        mainheader,
        mainbox,
        mainfooter
    },
    methods: {
        redirectToPetition() {
            let p = document.getElementById('hiddenP');
            if (!p) {
                p = document.createElement("p");
                p.setAttribute("type", "hidden");
                p.style.display = 'none';
                p.setAttribute("id", "hiddenP");
                document.body.appendChild(p);
            }
            p.innerHTML = '4';
            this.$router.push({name: 'petition'})
        }
    },
    metaInfo() {
    return {
      title: "Как оформлять счет-фактуры с маркированными товарами ?",
      meta: [
        {
          name: "description",
          content:
            "Статьи СЭД Faktura.EDO",
        },
      ],
    };
  },
}
</script>

<style scoped>

.paragraph {
    display: flex;
    margin-top: -20px;
}
.paragraph> p {
    margin-left: 10px;
}

.article-text-bold {
    margin-top: 0px;
    margin-bottom: 0px;
}
</style>